import React from 'react';
import classNames from 'classnames';

interface SharedIconProps {
  iconName: string;
  bold?: boolean;
  size?: 'sm' | 'md' | 'lg';
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  classes?: string;
}

export const SharedIcon = ({
  iconName,
  bold = false,
  size = 'md',
  color,
  classes = '',
}: SharedIconProps) => (
  <i
    className={classNames(
      'material-symbols-outlined',
      classes,
      color && 'color-' + color,
      {
        'fw-bold': bold,
        'fs-6': size === 'sm',
        'fs-5': size === 'md',
        'fs-3': size === 'lg',
      }
    )}
  >
    {iconName}
  </i>
);
