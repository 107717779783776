import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchAllDistributionList,
  fetchNotificationsByGroup,
} from 'actions/notificationActions';
import { fetchRostersForGroups } from 'actions/roleActions';
import { selectGroupGuid, useAppSelector } from '../../slices/commonSelectors';
import { Outlet } from 'react-router';

export default function NotificationsPage() {
  const dispatch = useDispatch();

  const currentGroupGuid = useAppSelector(selectGroupGuid);

  useEffect(() => {
    if (currentGroupGuid) {
      dispatch(fetchNotificationsByGroup(currentGroupGuid));
      dispatch(fetchRostersForGroups([currentGroupGuid]));
      dispatch(fetchAllDistributionList(currentGroupGuid));
    }
  }, [currentGroupGuid]);

  return (
    <>
      <div className="site-main">
        <div className="site-main__notification">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
