import PageTitle from '../headers/PageTitle';

const NotificationJobs = () => {
  return (
    <>
      <PageTitle title={'Notification Jobs'} />
      <div className="p-5">
        <span>Coming Soon.</span>
      </div>
    </>
  );
};

export default NotificationJobs;
