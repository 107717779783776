import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { upgradeUserSubscription } from 'actions/subscriptionManagementActions';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { useEffect } from 'react';

export const SubscriptionAddOnDialog = ({
  show,
  onClose,
  subscriptionData,
}) => {
  const { control, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(
      upgradeUserSubscription(
        subscriptionData?.subscription_id,
        subscriptionData?.current_status,
        data?.subscribed_mass_notification,
        data?.subscribed_international_messaging
      )
    );
    onClose();
  };

  useEffect(() => {
    setValue(
      'subscribed_mass_notification',
      subscriptionData?.subscribed_mass_notification
    );
    setValue(
      'subscribed_international_messaging',
      subscriptionData?.subscribed_international_messaging
    );
  }, [subscriptionData?.subscriptionId]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Subscription Add On</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="d-flex mb-3 align-item-center">
            <label className="form-label m-0 me-3">Mass Notifications</label>
            <Controller
              control={control}
              name="subscribed_mass_notification"
              defaultValue={false}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishSwitcher
                  value={value}
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="d-flex align-item-center">
            <label className="form-label m-0 me-3">
              International Messaging
            </label>
            <Controller
              control={control}
              name="subscribed_international_messaging"
              defaultValue={false}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishSwitcher
                  value={value}
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
