export const FETCH_CHAT_DGPT_ERROR = 'FETCH_CHAT_DGPT_ERROR'
export const FETCH_CHAT_DGPT_ONGOING = 'FETCH_CHAT_DGPT_ONGOING'
export const SET_DGPT_SITREP_TO_INITIAL_INCIDENT_REPORT_SUMMARY = 'SET_DGPT_SITREP_TO_INITIAL_INCIDENT_REPORT_SUMMARY'
export const EXPORT_SITREP_TO_PDF_REQUEST = 'EXPORT_SITREP_TO_PDF_REQUEST'
export const EXPORT_SITREP_TO_PDF_SUCCESS = 'EXPORT_SITREP_TO_PDF_SUCCESS'
export const EXPORT_SITREP_TO_PDF_ERROR = 'EXPORT_SITREP_TO_PDF_ERROR'

export const INIT_SITREP_REQUEST = 'INIT_SITREP_REQUEST'
export const INIT_SITREP_SUCCESS = 'INIT_SITREP_SUCCESS'
export const INIT_SITREP_ERROR = 'INIT_SITREP_ERROR'
export const FETCH_SITREPS_REQUEST = 'FETCH_SITREPS_REQUEST'
export const FETCH_SITREPS_ONGOING = 'FETCH_SITREPS_ONGOING'
export const FETCH_SITREPS_SUCCESS = 'FETCH_SITREPS_SUCCESS'
export const FETCH_SITREPS_ERROR = 'FETCH_SITREPS_ERROR'
export const REFRESH_SITREP_REQUEST = 'REFRESH_SITREP_REQUEST'
export const REFRESH_SITREP_SUCCESS = 'REFRESH_SITREP_SUCCESS'
export const REFRESH_SITREP_ERROR = 'REFRESH_SITREP_ERROR'

export const UPDATE_SITREP_SECTION_REQUEST = 'UPDATE_SITREP_SECTION_REQUEST'
export const UPDATE_SITREP_SECTION_SUCCESS = 'UPDATE_SITREP_SECTION_SUCCESS'
export const UPDATE_SITREP_SECTION_ERROR = 'UPDATE_SITREP_SECTION_ERROR'

export const ADD_SITREP_SECTION_REQUEST = 'ADD_SITREP_SECTION_REQUEST'
export const ADD_SITREP_SECTION_SUCCESS = 'ADD_SITREP_SECTION_SUCCESS'
export const ADD_SITREP_SECTION_ERROR = 'ADD_SITREP_SECTION_ERROR'

export const REMOVE_SITREP_SECTION_REQUEST = 'REMOVE_SITREP_SECTION_REQUEST'
export const REMOVE_SITREP_SECTION_SUCCESS = 'REMOVE_SITREP_SECTION_SUCCESS'
export const REMOVE_SITREP_SECTION_ERROR = 'REMOVE_SITREP_SECTION_ERROR'

export const FETCH_GROUP_AI_USAGE_REQUEST = 'FETCH_GROUP_AI_USAGE_REQUEST'
export const FETCH_GROUP_AI_USAGE_SUCCESS = 'FETCH_GROUP_AI_USAGE_SUCCESS'
export const FETCH_GROUP_AI_USAGE_ERROR = 'FETCH_GROUP_AI_USAGE_ERROR'

export const FETCH_ALL_GROUPS_AI_USAGE_REQUEST = 'FETCH_ALL_GROUPS_AI_USAGE_REQUEST'
export const FETCH_ALL_GROUPS_AI_USAGE_SUCCESS = 'FETCH_ALL_GROUPS_AI_USAGE_SUCCESS'
export const FETCH_ALL_GROUPS_AI_USAGE_ERROR = 'FETCH_ALL_GROUPS_AI_USAGE_ERROR'


export const FETCH_SOPS_REQUEST = 'FETCH_SOPS_REQUEST'
export const FETCH_SOPS_SUCCESS = 'FETCH_SOPS_SUCCESS'
export const FETCH_SOPS_ERROR = 'FETCH_SOPS_ERROR'

export const UPLOAD_SOP_REQUEST = 'UPLOAD_SOP_REQUEST'
export const UPLOAD_SOP_SUCCESS = 'UPLOAD_SOP_SUCCESS'
export const UPLOAD_SOP_ERROR = 'UPLOAD_SOP_ERROR'

export const DELETE_SOP_REQUEST = 'DELETE_SOP_REQUEST'
export const DELETE_SOP_SUCCESS = 'DELETE_SOP_SUCCESS'
export const DELETE_SOP_ERROR = 'DELETE_SOP_ERROR'

export const INIT_PLANAI_SUGGESTION_REQUEST = 'INIT_PLANAI_SUGGESTION_REQUEST'
export const INIT_PLANAI_SUGGESTION_SUCCESS = 'INIT_PLANAI_SUGGESTION_SUCCESS'
export const INIT_PLANAI_SUGGESTION_ERROR = 'INIT_PLANAI_SUGGESTION_ERROR'

export const FETCH_PLANAI_SUGGESTION_REQUEST = 'FETCH_PLANAI_SUGGESTION_REQUEST'
export const FETCH_PLANAI_SUGGESTION_SUCCESS = 'FETCH_PLANAI_SUGGESTION_SUCCESS'
export const FETCH_PLANAI_SUGGESTION_ERROR = 'FETCH_PLANAI_SUGGESTION_ERROR'

export const UPDATE_SUGGESTIONS_AFTER_SAVE = 'UPDATE_SUGGESTIONS_AFTER_SAVE'