import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Offcanvas, Row, Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import * as dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { IoMdCopy } from "react-icons/io";

import SOPs from 'components/DisasterGPT/SOPs';
import SharepointFiles from 'components/DisasterGPT/SharepointFiles';
import DataSources from 'components/DisasterGPT/DataSources';
import PromptLibrary from 'components/DisasterGPT/PromptLibrary';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';

import {
  setSelectedDChat,
  setCurrentSitrepId,
  cancelRun,
} from 'slices/dchatSlice';

import { usePollDChat, usePostDChat, useDeleteDisasterChat } from './hooks/useDisasterChats';
import { useSharepointFileRefs } from './hooks/useSharepointFileRefs';
import { useSopFileRefs } from './hooks/useSopFileRefs';
import { useSyncSharepointFiles } from './hooks/useSharepointFiles'; // Import the sync hook
import './ChatDisasterGPT.css';

const defaultRealtimeSelections = [
  { source: 'Web', description: 'Web Scraping', id: "" },
  { source: 'News', description: 'News Articles', id: "" },
]

const ChatDisasterGPT = ({ chatDGPTSession, sitrep, toggle, selectedDatetime }) => {
  const reduxDispatch = useDispatch();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendMessageTimestamp, setSendMessageTimestamp] = useState();
  const [docSelections, setDocSelections] = useState([]);
  const [sharepointSelections, setSharepointSelections] = useState([]);
  const [realtimeSelections, setRealtimeSelections] = useState(defaultRealtimeSelections);
  const [priorStatus, setPriorStatus] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isOutOfSync, setIsOutOfSync] = useState(false); // State to manage sync status

  const dchatStatus = useSelector((state) => state.dchat.status);
  const dchat = useSelector((state) => state.dchat.dchat);
  const streamtext = useSelector((state) => state.dchat.streamtext);

  const reduxCurrentlySelectedGroup = useSelector((state)=>{
    return state.app.currentlySelectedGroup
  })

  const { data: sharepointFileRefs, isFetching: isFetchingFileRefs } = useSharepointFileRefs(reduxCurrentlySelectedGroup?.group_guid);
  const { data: sopFileRefs, isFetching: isFetchingSopFileRefs } = useSopFileRefs(reduxCurrentlySelectedGroup?.group_guid);

  const { syncSharepointFiles, sharepointSyncSession, isSyncing } = useSyncSharepointFiles(); // Use the sync hook

  const pollDChat = usePollDChat();
  const postDChat = usePostDChat();
  const deleteDisasterChat = useDeleteDisasterChat();

  const messagesEndRef = useRef(null);

  const reduxCurrentIncident = useSelector((state)=>{
    return state.app.currentIncident
  })

  const [sharepointDriveId, setSharepointDriveId] = useState();
  const [tenantId, setTenantId] = useState();
  
  useEffect(() => {
    if (reduxCurrentIncident) {

      if (reduxCurrentIncident.sharepoint_location) {
        setSharepointDriveId(reduxCurrentIncident.sharepoint_location);
        setTenantId(reduxCurrentIncident.tenant_id);
      } 
    }
  }, [reduxCurrentIncident]);

  // Handle Sync Status
  useEffect(() => {
    if (sharepointFileRefs && sharepointFileRefs.length > 0) {
      const outOfSyncFiles = sharepointFileRefs.filter((file) => {
        const timestamp = dayjs(file.timestamp);
        return dayjs().diff(timestamp, 'hour') >= 24;
      });
      setIsOutOfSync(outOfSyncFiles.length > 0);
    }

  }, [sharepointFileRefs]);

  // Modified sendMessage function to handle docSelections and sharepointSelections separately
  function sendMessage(message, newChatName = "", prePromptData = null, prePromptSOPs = null, prePromptSharepoint = null) {
    const sopDocs = prePromptSOPs !== null ? prePromptSOPs : docSelections;
    const sharepointDocs = prePromptSharepoint !== null ? prePromptSharepoint : sharepointSelections;
    const data = prePromptData !== null ? prePromptData : realtimeSelections;

    let finalNewChatName = newChatName
    if(!!newChatName || !newChatName.length)
    {
      finalNewChatName = message.slice(0,50)
    }

    postDChat({
      dchat_id: dchat.id,
      message: message,
      docSelections: sopDocs,
      sharepointSelections: sharepointDocs,
      realtimeSelections: data,
      selectedDatetime: selectedDatetime,
      newChatName: finalNewChatName,
      tenantId: tenantId,
      sharepointDriveId: sharepointDriveId,
    });

    setMessages([
      ...messages,
      {
        role: 'USER',
        content: message,
        timestamp: new Date(),
        docSelections: sopDocs,
        sharepointSelections: sharepointDocs,
        realtimeSelections: data,
        selectedDatetime: selectedDatetime,
      },
    ]);
    setInput('');
    setSendMessageTimestamp(new Date());
  }

  useEffect(() => {
    pollDChat(sitrep);
  }, [sitrep]);

  useEffect(() => {
    if (!!dchat) {
      if (dchat.status !== 'Complete' && dchat.status !== 'Error') {
        setPriorStatus(dchat.status);
      } else if (
        (dchat.status === 'Complete' && priorStatus !== 'Complete') ||
        (dchat.status === 'Error' && priorStatus !== 'Error')
      ) {
        setPriorStatus('Initializing');
      } else {
        setPriorStatus(dchat.status);
      }
    }
  }, [dchat]);

  useEffect(() => {
    if (dchat?.messages) {
      let showMsg = [...dchat.messages];
      if (streamtext && (dchatStatus === 'loading' || dchatStatus === 'ongoing')) {
        const streamMsg = {
          role: 'ASSISTANT',
          content: streamtext,
        };
        showMsg = [...showMsg, streamMsg];
      }
      setMessages(showMsg);
    }
    if (!dchat || Object.keys(dchat).length === 0) {
      setMessages([]);
    }

    if (dchat?.messages?.length) {
      const lastMessage = dchat.messages[dchat.messages.length - 1];
      const sopSel = lastMessage.docSelections || [];
      const sharepointSel = lastMessage.sharepointSelections || [];
      const rsel = lastMessage.realtimeSelections || [];
      
      setDocSelections(sopSel);
      setSharepointSelections(sharepointSel);
      setRealtimeSelections(rsel);
    }
  }, [dchat, streamtext]);

  useEffect(() => {
    if (messagesEndRef.current && !(dchatStatus === 'loading' || dchatStatus === 'ongoing')) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  function cancelRunClicked() {
    reduxDispatch(cancelRun(dchat));
  }

  function deleteSessionClicked() {
    if (dchat.id) {
      setShowDeleteModal(true);
    }
  }

  function handleConfirmDelete() {
    deleteDisasterChat.mutate(dchat.id);
    closeClicked();
    setShowDeleteModal(false);
  }

  function handleCancelDelete() {
    setShowDeleteModal(false);
  }

  function closeClicked() {
    reduxDispatch(setSelectedDChat({}));
    reduxDispatch(setCurrentSitrepId());
    toggle();
  }

  function areSelectionsValid() {
    if (!!realtimeSelections.length) {
      const sitrepSelection = realtimeSelections.find((s) => s.source === 'SITREP');
      if (!!sitrepSelection && !sitrepSelection.id) {
        return false;
      }
    }
    return true;
  }

  function copyToClipboard() {
    const formattedMessages = messages
      .map((message) => {
        const timestamp = message.timestamp
          ? dayjs(message.timestamp).format('YYYY-MM-DD HH:mm')
          : '(No datetime provided)';
        return `${message.role.toUpperCase()} [${timestamp}]:\n${message.content}\n\n`;
      })
      .join('');

    navigator.clipboard.writeText(formattedMessages).catch((err) => console.error('Error copying messages: ', err));
  }

  const onUsePrompt = (prompt) => {
    const structuredPrompt = `# ${prompt.title}\n\n${prompt.description}`;
    let promptSOPs = docSelections;
    let promptSharepoint = sharepointSelections;
    let promptData = realtimeSelections;

    // Set docSelections, sharepointSelections, and realtimeSelections to the prompt's settings
    if (prompt.settings) {
        // Documents (SOPs) are distinct from SharePoint files
        if (prompt.settings.documents) {
          const sopDocs = prompt.settings.documents.filter(doc => doc.source === 'SOP');
          setDocSelections(sopDocs);
          promptSOPs = sopDocs;  // Update SOP selections only
        }

        // SharePoint files are separate from SOPs
        if (prompt.settings.sharepoint) {
          const sharepointDocs = prompt.settings.sharepoint;
          setSharepointSelections(sharepointDocs);  // Update SharePoint selections
          promptSharepoint = sharepointDocs;  // Update SharePoint selections only
        }

        if (prompt.settings.datasources) {
          setRealtimeSelections(prompt.settings.datasources);
          promptData = prompt.settings.datasources;
        }
    }

    // Send message with updated selections for SOPs, SharePoint, and data sources
    sendMessage(structuredPrompt, prompt.title, promptData, promptSOPs, promptSharepoint);
  };

  return (
    <DrawerWrapper toggle={closeClicked} title={`DisasterChat - ${dchat?.name || '(New chat)'}`} fullscreen={false}>
      <Offcanvas.Body>
        <div>
          {messages.map((message, index) => {
            let timestamp = index === messages.length - 1 ? sendMessageTimestamp : message.timestamp;
            if (timestamp) {
              timestamp = dayjs(timestamp).format('YYYY-MM-DD HH:mm');
            } else {
              timestamp = '(No datetime provided)';
            }
            // Regex to remove annotations
            let messageContent = message?.content.replace(/\*\*\[[^\]]*\]\*\*|【[^】]*】/g, '');
            
            return (
              <div key={'dpgt-message-' + index}>
                <div>
                  <strong>{message.role.toUpperCase()}:</strong>
                  <IoMdCopy
                    className="clipboard-icon"
                    onClick={() => navigator.clipboard.writeText(message.content)}
                    title="Copy to clipboard"
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                  />
                </div>
                <div>
                  <label>{timestamp}</label>
                  <div className="EventAIAnalysis-paragraph">
                    <ReactMarkdown
                      children={messageContent}
                      components={{
                        a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />,
                      }}
                    />
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
          
        </div>
        <Row>
          {dchatStatus === 'loading' || dchatStatus === 'ongoing' ? (
            <div>
              <h5>
                {priorStatus}&nbsp;<i className="fa fa-spinner fa-pulse"></i>
              </h5>
              <div>
                <span>DisasterChat on average is 10-60 seconds but depending on the volume of data can take longer to generate an answer.</span>
              </div>
            </div>
          ) : (
            <div className="ChatDisasterGPT-input-wrap">
              <StylishNewTextArea
                type="text"
                value={input}
                disabled={dchatStatus === 'loading' || dchatStatus === 'ongoing'}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => (e.key === 'Enter' ? sendMessage(input) : null)}
                placeholder={'Message DisasterGPT...'}
              />
              <div>
                <label>DisasterChat can make mistakes. Always check important information.</label>
              </div>
              <div className="ChatDisasterGPT-input-button-wrap">
                <StylishNewButton
                  disabled={!areSelectionsValid() || isSyncing || !sharepointSyncSession}
                  onClick={() => sendMessage(input)}
                >
                  {isSyncing ? <i className="fa fa-spinner fa-pulse"></i> : 'Send'}
                </StylishNewButton>
              </div>
              <Row>
                <div>
                  <StylishNewButton
                    onClick={() => syncSharepointFiles(true)}
                    disabled={isSyncing || dchatStatus === 'loading' || dchatStatus === 'ongoing'}
                    className="ml-2" // Optional: Add margin for spacing
                  >
                    {isSyncing ? <i className="fa fa-spinner fa-pulse"></i> : 'File Sync'}
                  </StylishNewButton>
                  {isSyncing && (
                    <p>File Sync can take several minutes.</p>
                  ) || (
                    <p>File Sync sends your files to AI.  Run this when new files have been uploaded.  Files in AI expire after 24 hours.</p>
                  )}
                </div>
              </Row>
              {!areSelectionsValid() && <span>Choose a SITREP to send to DisasterChat</span>}
            </div>
          )}
          <div ref={messagesEndRef} />
        </Row>
          
        <Row>
          <DataSources selections={realtimeSelections} setSelections={setRealtimeSelections} />
        </Row>
        <Row>
          {/* Include the PromptLibrary component */}
          <PromptLibrary onUsePrompt={onUsePrompt} />
        </Row>
        <Row>
          <SOPs selections={docSelections} setSelections={setDocSelections} />
        </Row>
        <Row>
          <SharepointFiles selections={sharepointSelections} setSelections={setSharepointSelections} />
        </Row>
        
      </Offcanvas.Body>
      <DrawerFooter>
        <div className="button-group">
          {dchatStatus === 'loading' || (dchatStatus === 'ongoing' && !!dchat) && (
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={cancelRunClicked}
            >
              Cancel Current Run
            </StylishNewButton>
          )}

          {!!dchat && !!dchat.id && (
            <>
              <StylishNewButton className="button--secondary button--reverse" type="button" onClick={copyToClipboard}>
                Copy to Clipboard
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={deleteSessionClicked}
              >
                DELETE SESSION
              </StylishNewButton>
            </>
          )}

          <StylishNewButton className="button--secondary button--reverse" type="button" onClick={closeClicked}>
            Close
          </StylishNewButton>
        </div>
      </DrawerFooter>
      <Modal
        show={showDeleteModal}
        onHide={handleCancelDelete}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this DisasterChat session?
        </Modal.Body>
        <Modal.Footer>
          <StylishNewButton variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </StylishNewButton>
          <StylishNewButton variant="danger" onClick={handleConfirmDelete}>
            Delete
          </StylishNewButton>
        </Modal.Footer>
      </Modal>
    </DrawerWrapper>
  );
};

export default ChatDisasterGPT;