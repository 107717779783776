import React, { useEffect, useState } from 'react';
import IconFile from 'images/icons/icon__file.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { generateSignedUrl } from 'actions/diceActions';
import { useForm } from 'react-hook-form';
import {
  getAllInitialIncidentReportLogs,
  getInitialIncidentReportById,
  initialIncidentReportCreateComment,
  upsertInitialIncidentReport,
} from 'actions/ReportActions';
import { fetchIncidents } from 'actions/profileActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { PossibleReportStatuses } from './ReportConstant';
import { useLocation } from 'react-router-dom';
import getQueryParams from 'utils/getQueryParams';
import {
  fetchNestedCategories,
  fetchNestedCategoryItems,
} from 'actions/locationAction';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import { sanitizeHtml } from '../../utils/sanitizeHtml';
import PageTitle from 'components/headers/PageTitle';

export default function ViewReport({ selectedReport, onChangeTab }) {
  const reduxDispatch = useDispatch();
  const location = useLocation();
  const [incidentAfterActionReport, setIncidentAfterActionReport] = useState(
    []
  );
  const [exerciseAfterActionReport, setExerciseAfterActionReport] = useState(
    []
  );
  const [isLoaded, setIsLoaded] = useState(true);
  const [isEscalateLoading, setIsEscalateLoading] = useState(true);
  const [currentStatus, setCurrentStatus] = useState(
    selectedReport?.current_status
  );
  const [availableStatuses, setAvailableStatuses] = useState(
    PossibleReportStatuses
  );
  const [report, setReport] = useState(null);
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const allInitialIncidentReportLogs = useSelector((state) =>
    state.app.allInitialIncidentReportLogs.map((item) => {
      return {
        ...item,
        name:
          item.user_name ||
          item.profile_settings.userName ||
          item.email_address,
        created_at: moment(item.created_at).format('MM/DD/YYYY HH:mm'),
      };
    })
  );

  const isAllInitialIncidentReportLogsLoaded = useSelector(
    (state) => state.app.isAllInitialIncidentReportLogsLoaded
  );
  const isAddInitialIncidentReportCommentLoaded = useSelector(
    (state) => state.app.isAddInitialIncidentReportCommentLoaded
  );
  const isAllInitialIncidentReportLoaded = useSelector((state) => {
    return state.app.isAllInitialIncidentReportLoaded;
  });
  const isAddInitialIncidentReportLoaded = useSelector((state) => {
    return state.app.isAddInitialIncidentReportLoaded;
  });
  const singleInitialIncidentReport = useSelector((state) => {
    return state.app.singleInitialIncidentReport;
  });

  const currentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  useEffect(() => {
    if (!!currentlySelectedGroup && !!currentlySelectedGroup.group_guid) {
      reduxDispatch(fetchNestedCategories());
      reduxDispatch(fetchNestedCategoryItems());
    }
  }, [currentlySelectedGroup]);

  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });

  const reduxNestedCategories = useSelector((state) => {
    return state.app.nestedCategories;
  });

  useEffect(() => {
    if (location.search) {
      const queryParams = getQueryParams(location.search);
      if (queryParams.id && !selectedReport) {
        reduxDispatch(getInitialIncidentReportById(queryParams.id));
      }
    }
  }, [location]);
  useEffect(() => {
    if (singleInitialIncidentReport && !selectedReport) {
      setReport(singleInitialIncidentReport);
    } else if (selectedReport) {
      setReport(selectedReport);
    }
  }, [singleInitialIncidentReport, selectedReport]);

  useEffect(() => {
    if (!!report) {
      reduxDispatch(getAllInitialIncidentReportLogs(report?.id));
      setIncidentAfterActionReport(report.incident_after_action_report);
      setExerciseAfterActionReport(report.exercise_after_action_report);
      setCurrentStatus(report.current_status);
    }
  }, [report]);

  useEffect(() => {
    if (currentStatus) {
      const isEscalated = currentStatus.includes('Escalated');

      if (isEscalated) {
        setAvailableStatuses(
          PossibleReportStatuses.filter((status) =>
            status?.value?.includes('Escalated')
          )
        );
      } else {
        setAvailableStatuses(
          PossibleReportStatuses.filter(
            (status) => !status?.value?.includes('Escalated')
          )
        );
      }
    }
  }, [currentStatus]);

  useEffect(() => {
    if (
      !isLoaded &&
      isAllInitialIncidentReportLogsLoaded &&
      isAddInitialIncidentReportCommentLoaded
    ) {
      reset();
      setIsLoaded(true);
    }
  }, [
    isAddInitialIncidentReportCommentLoaded,
    isAllInitialIncidentReportLogsLoaded,
    isLoaded,
  ]);

  useEffect(() => {
    if (
      !isEscalateLoading &&
      isAllInitialIncidentReportLoaded &&
      isAddInitialIncidentReportLoaded
    ) {
      setIsEscalateLoading(true);
      reduxDispatch(fetchIncidents());
      setCurrentStatus('Escalated');
      reduxDispatch(getAllInitialIncidentReportLogs(report?.id));
    }
  }, [
    isAllInitialIncidentReportLoaded,
    isAddInitialIncidentReportLoaded,
    isEscalateLoading,
    onChangeTab,
  ]);

  const downloadFile = async (file) => {
    const d = await reduxDispatch(generateSignedUrl(file.trim()));
    window.open(d, '_blank');
  };

  const onEscalateIncident = () => {
    if (
      confirm(`Are you sure you want to Generate ICS Forms for this incident?`)
    ) {
      reduxDispatch(
        upsertInitialIncidentReport({
          ...report,
          answer: report.answer.map((item) => {
            return {
              id: item.id,
              label: item.answer,
              value: item.answer,
            };
          }),
          current_status: 'Forms Generated',
          type: 'Update',
        })
      );
      setIsEscalateLoading(false);
    }
  };
  const onAddComment = async (data) => {
    const comment = {
      comment: data.comment,
      type: 'Comment',
    };
    await reduxDispatch(
      initialIncidentReportCreateComment(report?.id, comment)
    );
    setIsLoaded(false);
    clearErrors('comment');
    reset();
  };

  const columns_report_details = [
    {
      text: 'Name',
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <button
                className="button--icon primary weight-700 rounded-circle p-0 me-3"
                style={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                  fontSize: '12px',
                }}
              >
                {row.name
                  .match(/(\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </button>
              {row.name}
            </div>
          </>
        );
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      sort: true,
      attrs: { title: 'Created At' },
    },
  ];

  const expandRow_report_details = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      const clean = sanitizeHtml(row.comment);
      return (
        <>
          <label className="form-label d-block">Comment</label>
          <div
            className="m-0"
            dangerouslySetInnerHTML={{ __html: clean }}
          ></div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      if (expandable) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        );
      }
    },
  };

  const onSelectIncidentStatus = (e) => {
    setCurrentStatus(e.value);
    reduxDispatch(
      upsertInitialIncidentReport({
        ...report,
        answer: report.answer.map((item) => {
          return {
            id: item.id,
            label: item.answer,
            value: item.answer,
          };
        }),
        current_status: e.value,
        type: 'Update',
      })
    );
  };

  const [categoryName, setCategoryName] = useState();
  const [itemName, setItemName] = useState();

  useEffect(() => {
    if (!!reduxNestedCategories && !!reduxNestedCategoryItems && !!report) {
      const item = reduxNestedCategoryItems.find(
        (catItem) => catItem.id === report?.aor_id
      );

      const category = reduxNestedCategories.find(
        (listedCat) => listedCat.id === item?.category_id
      );

      setCategoryName(category?.category_name);
      setItemName(item?.category_item_name);
    }
  }, [reduxNestedCategories, reduxNestedCategoryItems, report]);

  return (
    <>
      <PageTitle title='Initial Incident Report'/>
      <StylishNewButton
        className="button--secondary mb-4 w-100 w-md-auto"
        onClick={() => onChangeTab(undefined, 'View')}
      >
        View All Initial Incident Report
      </StylishNewButton>
      <div className="form-block mb-4">
        {report && (
          <div className="row">
            <div className="col-md-12 mb-4">
              <h4 className="m-0">Details</h4>
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">Incident name</h6>
              {report.incident_name}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">Incident Type</h6>
              {report.incident_types
                ? report.incident_types.join(', ')
                : report.incident_type}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">Start Date</h6>
              {moment(report.start_date).format('MMMM Do YYYY')}
            </div>
            <div className="w-100 d-none d-md-block"></div>
            {categoryName && itemName && (
              <div className="col-md-4 mb-3">
                <h6 className="mb-0 apply-weight">{categoryName}</h6>
                {itemName}
              </div>
            )}
            {/* <div className="col-md-4 mb-3"> */}
            {/*     <h6 className="mb-0 apply-weight">Location - Entity</h6> */}
            {/*     {report.location_entity} */}
            {/* </div> */}
            {/* <div className="col-md-4 mb-3"> */}
            {/*     <h6 className="mb-0 apply-weight">Location - Sub Entity</h6> */}
            {/*     {report.location_sub_entity} */}
            {/* </div> */}
            {/* <div className="col-md-4 mb-3"> */}
            {/*     <h6 className="mb-0 apply-weight">Location - Enterprise</h6> */}
            {/*     {report.location_enterprise} */}
            {/* </div> */}
            {/* <div className="col-md-4 mb-3"> */}
            {/*     <h6 className="mb-0 apply-weight">Incident Location (asset)</h6> */}
            {/*     {report.location_asset} */}
            {/* </div> */}
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">
                ISC Personnel Directly Involved
              </h6>
              None
            </div>
            <div className="col-md-12">
              <h6 className="mb-0 apply-weight">Brief Synopsis</h6>
              <p className="m-0">{report.incident_description}</p>
            </div>
            <div className="col-md-12">
              <hr className="dashed" />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <h6 className="mb-0 apply-weight">Name of Person Calling</h6>
              {report.profile_settings?.userName ||
                report.calling_persion_name ||
                ''}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <h6 className="mb-0 apply-weight">Call Back Number</h6>
              {report.profile_settings?.mobile_phone_number ||
                report.calling_persion_number ||
                ''}
            </div>
            <div className="col-md-4">
              <h6 className="mb-0 apply-weight">Type of Call</h6>
              {report.call_type}
            </div>
            <div className="col-md-12">
              <hr className="dashed" />
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">
                Is this a QI (Qualified Individual) Regulatory Drill?
              </h6>
              {report.is_qi ? 'Yes' : 'No'}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">
                Did the Facility report to Abandon Stations?
              </h6>
              {report.is_facility_report_to_abandon_stations ? 'Yes' : 'No'}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">Did the Facility Muster?</h6>
              {report.is_facility_muster ? 'Yes' : 'No'}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">Relevant Teams Identified</h6>
              {report.teams_activated_list &&
                report.teams_activated_list.length > 0 &&
                report.teams_activated_list.join(', ')}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="mb-0 apply-weight">Resources Activated</h6>
              <p className="m-0">{report.resources_activated}</p>
            </div>
            {report.checklist_data &&
              report.checklist_data.length > 0 &&
              report.checklist_data.map((site, index) => {
                const clean = sanitizeHtml(site.site_checklist);
                return (
                  <div className="col-md-12 mb-3" key={site.id}>
                    <h6 className="mb-0 apply-weight">
                      Site Checklist {index + 1}
                    </h6>
                    <div
                      className="m-0"
                      dangerouslySetInnerHTML={{ __html: clean }}
                    ></div>
                  </div>
                );
              })}
            {report.team_checklist_data &&
              report.team_checklist_data.length > 0 &&
              report.team_checklist_data.map((team, index) => {
                const clean = sanitizeHtml(team.team_checklist);
                return (
                  <div className="col-md-12" key={team.id}>
                    <h6 className="mb-2 apply-weight">
                      Team Checklist {index + 1}
                    </h6>
                    <div
                      className="m-0"
                      dangerouslySetInnerHTML={{ __html: clean }}
                    ></div>
                  </div>
                );
              })}
            {(!!incidentAfterActionReport &&
              incidentAfterActionReport.length > 0) ||
              (!!exerciseAfterActionReport &&
                exerciseAfterActionReport.length > 0 && (
                  <>
                    <div className="col-md-12">
                      <hr className="dashed" />
                    </div>
                    <div className="col-md-12 mb-4">
                      <h4 className="m-0">Attachments</h4>
                    </div>
                  </>
                ))}
            {!!incidentAfterActionReport &&
              incidentAfterActionReport.length > 0 && (
                <div className="col-md-12 mb-2">
                  <label className="form-label m-0">
                    Incident After Action Report (File Upload)
                  </label>
                  <div className="row">
                    {incidentAfterActionReport.map((file, index) => {
                      return (
                        <div
                          key={file}
                          className="col-md-3 col-xl-2 my-2"
                          onClick={() => downloadFile(file)}
                        >
                          <div className="cards cards__uploaded-file">
                            {/* <span className='remove' onClick={() => deleteFile(index, 'IncidentAfterActionReport')}>
                                                        <img src={IconClose} alt="" />
                                                    </span> */}
                            <span className="icon">
                              <img src={IconFile} alt="" />
                            </span>
                            <span className="title">
                              {file.split('/').pop()}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            {!!exerciseAfterActionReport &&
              exerciseAfterActionReport.length > 0 && (
                <div className="col-md-12 mb-2">
                  <label className="form-label m-0">
                    Exercise After Action Report (File Upload)
                  </label>
                  <div className="row">
                    {exerciseAfterActionReport.map((file, index) => {
                      return (
                        <div
                          key={file}
                          className="col-md-3 col-xl-2 my-2"
                          onClick={() => downloadFile(file)}
                        >
                          <div className="cards cards__uploaded-file">
                            {/* <span className='remove' onClick={() => deleteFile(index, 'ExerciseAfterActionReport')}>
                                                        <img src={IconClose} alt="" />
                                                    </span> */}
                            <span className="icon">
                              <img src={IconFile} alt="" />
                            </span>
                            <span className="title">
                              {file.split('/').pop()}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            <div>
              <h5 className="mb-2">Status</h5>
              <StylishNewSelect
                options={availableStatuses}
                defaultValue={currentStatus}
                onChange={(e) => onSelectIncidentStatus(e)}
                placeholder={'Select Report Status'}
                isClearable={true}
                isSearchable={true}
                isMulti={false}
                isDisabled={false}
              />
            </div>
          </div>
        )}
      </div>
      <div className="form-block">
        <div className="mb-4 mb-md-5">
          <h4 className="mb-4">Activity Logs</h4>
          <StylishNewTable
            keyField={'id'}
            columns={columns_report_details}
            rows={allInitialIncidentReportLogs}
            expandRow={expandRow_report_details}
          />
        </div>
        <h4 className="mb-4">Add Comment</h4>
        <form onSubmit={handleSubmit(onAddComment)}>
          <div className="mb-3">
            <TinyMCEWithFormController
              name="comment"
              control={control}
              placeholder="Write a Comment"
              readOnly={false}
              rules={{ required: true }}
            />
            {errors.comment && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <StylishNewButton
            className="button--primary w-100 w-md-auto"
            type={'submit'}
          >
            Submit Comment
          </StylishNewButton>
          {!isLoaded && <div className="mt-2">loading...</div>}
        </form>
      </div>
    </>
  );
}
