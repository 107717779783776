import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { fetchGroupAIUsage } from 'actions/dgptActions';

const inputTokenPricePerMillion = 5;
const outputTokenPricePerMillion = 15;

function calculateTotalTokenCost(data) {
  
  const totalCost = data.reduce((acc, entry) => {
    return acc + entry.inputTokenCost + entry.outputTokenCost;
  }, 0);
  
  return totalCost;
}

export default function GroupAIUsage({group_guid,group_name}) {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const reduxDispatch = useDispatch();

  const reduxFeatureFlags = useSelector((state) => state.app.featureFlags);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const isFetchGroupAIUsageLoaded = useSelector((state) => state.app.isFetchGroupAIUsageLoaded);
  const reduxGroupAIUsage = useSelector((state) => {
    const usage = state.app.groupAIUsage;
    const gg = group_guid || reduxCurrentlySelectedGroup?.group_guid;
    return usage[gg]
  });

  useEffect(() => {
    if (reduxCurrentlySelectedGroup && reduxCurrentlySelectedGroup.group_guid) {
      reduxDispatch(fetchGroupAIUsage({group_guid: !!group_guid && group_guid || reduxCurrentlySelectedGroup.group_guid}));
    }
  }, [reduxCurrentlySelectedGroup]);

  const daysInMonth = (year, month) => new Date(year, month, 0).getDate();
  const getMonthName = (month) => new Date(0, month - 1).toLocaleString('en-US', { month: 'long' });

  let option;
  let aggregatedData = {}
  let chartData
  if (reduxGroupAIUsage) {
    const days = daysInMonth(selectedYear, selectedMonth);
    const monthName = getMonthName(selectedMonth);

    // Initialize all days in the month
    for (let i = 1; i <= days; i++) {
      const day = String(i);
      aggregatedData[`${monthName} ${day}`] = { prompt_tokens: 0, completion_tokens: 0 };
    }

    // Aggregate data
    reduxGroupAIUsage.forEach(item => {
      const date = new Date(item.timestamp);
      const itemYear = date.getFullYear();
      const itemMonth = date.getMonth() + 1;
      const itemDay = date.getDate();

      if (itemYear === selectedYear && itemMonth === selectedMonth) {
        const key = `${monthName} ${itemDay}`;
        if (aggregatedData[key] !== undefined) {
          aggregatedData[key].prompt_tokens += item.prompt_tokens;
          aggregatedData[key].completion_tokens += item.completion_tokens;
        }
      }
    });

    // Prepare data for the chart
    chartData = Object.keys(aggregatedData).map(date => ({
      date: date,
      prompt_tokens: aggregatedData[date].prompt_tokens,
      completion_tokens: aggregatedData[date].completion_tokens,
      inputTokenCost: (aggregatedData[date].prompt_tokens / 1_000_000) * inputTokenPricePerMillion,
      outputTokenCost: (aggregatedData[date].completion_tokens / 1_000_000) * outputTokenPricePerMillion
    }));

    // ECharts configuration
    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params) => {
          const promptData = params.find(param => param.seriesName === 'Prompt Tokens');
          const completionData = params.find(param => param.seriesName === 'Completion Tokens');
          let totalCost = (promptData.data.inputTokenCost + completionData.data.outputTokenCost).toFixed(2)
          return `${promptData.data.date}<br/>
                  Prompt Tokens: ${promptData.data.value}<br/>
                  Completion Tokens: ${completionData.data.value}<br/>
                  Input Cost: $${promptData.data.inputTokenCost.toFixed(2)}<br/>
                  Output Cost: $${completionData.data.outputTokenCost.toFixed(2)}<br/>
                  Total Cost: $${totalCost}`;
        }
      },
      legend: {
        data: ['Prompt Tokens', 'Completion Tokens'],
        textStyle: {
          color: '#ffffff' // Set text color to white
        }
      },
      xAxis: {
        type: 'category',
        data: chartData.map(item => item.date)
      },
      yAxis: {
        type: 'value',
        name: 'Tokens'
      },
      series: [
        {
          name: 'Prompt Tokens',
          data: chartData.map(item => ({
            date: item.date,
            value: item.prompt_tokens,
            inputTokenCost: item.inputTokenCost
          })),
          type: 'bar',
          stack: 'tokens',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        },
        {
          name: 'Completion Tokens',
          data: chartData.map(item => ({
            date: item.date,
            value: item.completion_tokens,
            outputTokenCost: item.outputTokenCost
          })),
          type: 'bar',
          stack: 'tokens'
        }
      ]
    };
  }


  let totalSpendForMonth = !!chartData && calculateTotalTokenCost(chartData) || 0
  let overage = totalSpendForMonth - 50
  if(overage < 0) overage = 0

  return (
    <>
      <h4>AI Usage for {group_name||reduxCurrentlySelectedGroup.group_name}</h4>
      <h5>Monthly allotment: $50</h5>
      <h5>Usage for month of {getMonthName(selectedMonth)} {selectedYear}: ${totalSpendForMonth.toFixed(2)}</h5>
      <h5>Overage: ${overage}</h5>
      {overage>0 && (
        <p><strong>You will be billed for overage at the end of the month, currently ${overage*1.5}</strong></p>
      )}
      <p>AI usage is billed at a rate of $5 per 1M input tokens, and $15 per 1M output tokens.  Input tokens include data sources for AutoSITREP and context for PlanAI. Licensed users are alloted $50 per month of AI usage, after which Overage is billed at 50% over cost.</p>


      
      <label>
        <select value={selectedMonth} onChange={(e) => setSelectedMonth(Number(e.target.value))}>
          {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
            <option key={month} value={month}>{getMonthName(month)}</option>
          ))}
        </select>
      </label>
      <label>
        <select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))}>
          {Array.from({ length: new Date().getFullYear() - 2023 }, (_, i) => 2024 + i).map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </label>
      {!isFetchGroupAIUsageLoaded && (
        <div>Loading AI Usage...<i className="fa fa-spinner fa-pulse"></i></div>
      ) || (
        <>
          {(reduxGroupAIUsage && option) && (
            <div className="form-block">
              <ReactECharts option={option} style={{ height: 400 }} />
            </div>
          )}
        </>
      )}
    </>
  );
}
