import { useAppSelector } from '../slices/commonSelectors';

export const useFeatureFlag = (flag: string) => {
  const flags = useAppSelector((s) => s.app.featureFlags as string[]);
  return flags.includes(flag);
};

export const useFullIAPFlag = () => {
  return useFeatureFlag('FULL_INCIDENT_MANAGEMENT_LANDING_PAGE');
};

export const useCreateTeamsSiteForExerciseFlag = () => {
  return useFeatureFlag('CREATE_TEAMS_SITE_FOR_EXERCISE');
};

export const useNotificationJobsFlag = () => {
  return useFeatureFlag('NOTIFICATION_JOBS');
};

export const useStreamlinePlatformFlag = () => {
  return useFeatureFlag('STREAMLINE_PLATFORM');
};
