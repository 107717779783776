import React from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

export const StylishInputPhone = ({
  className,
  defaultCountry,
  placeholder,
  value,
  onChange,
  isValid,
}) => {
  const isValidPhone = (value) => {
    if (!value || !isValidPhoneNumber(value)) {
      isValid(false);
    } else {
      isValid(true);
    }
  };

  return (
    <PhoneInput
      defaultCountry={defaultCountry}
      className={`form-phone-input ${!!className ? className : ''}`}
      placeholder={!!placeholder ? placeholder : 'Phone No'}
      value={value}
      onChange={(e) => {
        isValidPhone(e);
        return onChange(e);
      }}
    />
  );
};
