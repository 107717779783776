import { toastConfig } from 'assets/data/config';
import axios from 'axios';
import config from 'constants/apiConfig';
import { FETCH_ALL_USER_WITH_SUBSCRIPTION } from 'constants/subscription-management-types';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

export const fetchUserWithSubscription = (page, searchterm) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const users = await axios.post(
        config.apiGateway.getAllUserWithSubscription,
        {
          page: page || 1,
          term: searchterm || '',
        }
      );
      dispatch({
        type: FETCH_ALL_USER_WITH_SUBSCRIPTION,
        payload: users.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.ERROR, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
};

export const upgradeUserSubscription = (
  subscriptionId,
  status,
  subscribed_mass_notification,
  subscribed_international_messaging
) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await axios.post(config.apiGateway.upgradeUserSubscription, {
        upgradeSubscription: {
          subscription_id: subscriptionId,
          current_status: status,
          subscribed_mass_notification,
          subscribed_international_messaging,
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.ERROR, toastConfig);
    } finally {
      dispatch(fetchUserWithSubscription());
      dispatch(endLoading());
    }
  };
};

export const bulkUpgradeUserSubscription = (userData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await axios.post(config.apiGateway.bulkUpgradeUserSubscription, {
        subscriptions: userData,
      });
    } catch (error) {
      toast.error(error?.response?.data?.ERROR, toastConfig);
    } finally {
      dispatch(fetchUserWithSubscription());
      dispatch(endLoading());
    }
  };
};
