import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { ListGroupNavItem } from './ListGroupNavComponents';
import { useNotificationJobsFlag } from '../../utils/featureFlags';

const NotificationsNavPanel: React.FC<{ navBack: () => void }> = ({
  navBack,
}) => {
  const notificationJobsFlag = useNotificationJobsFlag();
  return (
    <nav aria-label="secondary sidebar" className="second-panel">
      <div className="second-panel-back">
        <button
          className="button button--icon border-0 bg-bglight"
          onClick={navBack}
        >
          <i className="material-symbols-outlined">arrow_back</i>
        </button>
        <div>Notifications</div>
      </div>
      <ListGroup>
        <div className="list-group-title">Notifications</div>
        <ListGroupNavItem
          to="/notifications/compose"
          icon="send"
          label="Compose"
          end
        />
        <ListGroupNavItem
          to="/notifications/logs"
          icon="history"
          label="Logs"
        />
        <ListGroupNavItem
          to="/notifications/jobs"
          icon="event_repeat"
          label="Recurring Notifications"
          isDisabled={!notificationJobsFlag}
        />
      </ListGroup>
    </nav>
  );
};

export default NotificationsNavPanel;
