import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import moment from 'moment';
import IconDownload from '../../assets/images/icon__download.svg';
import { IAPForm208AddEditFormDialog } from './IAPForm208AddEditFormDialog';
import {
  deleteSaftyAndHealthForm,
  getAllSaftyAndHealthForm,
} from 'actions/IAPForm208Action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { generateSignedUrl } from 'actions/diceActions';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const IAPForm208 = () => {
  const reduxDispatch = useDispatch();
  const [showAddEditFormDialog, setShowAddEditFormDialog] = useState(false);
  const [updateSaftyFormData, setUpdateSaftyFormData] = useState(null);
  const [deleteSaftyFormData, setUDeleteSaftyFormData] = useState(null);
  const [dialogType, setDialogType] = useState('New');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const safetyAndHealthForms = useSelector((state) => {
    return state.app.safetyAndHealthForms;
  });
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const isValidateRBACPermissionForActionLoaded = useSelector((state) => {
    return state.app.isValidateRBACPermissionForActionLoaded;
  });
  useEffect(() => {
    if (reduxCurrentIncident && reduxCurrentIncident.current_period) {
      reduxDispatch(getAllSaftyAndHealthForm());
    }
  }, [reduxCurrentIncident]);

  const columns = [
    {
      dataField: 'form_name',
      text: 'Form Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Form Name' },
    },
    {
      dataField: 'form_number',
      text: 'Number',
      sort: true,
      attrs: { title: 'Number' },
    },
    {
      dataField: 'use',
      text: 'Use',
      sort: true,
      attrs: { title: 'Use' },
    },
    {
      dataField: 'required',
      text: 'Required',
      sort: true,
      attrs: { title: 'Required' },
      formatter: (cell, row, rowIndex) =>
        row?.required === true ? 'Yes' : 'No',
      headerStyle: {
        width: '150px',
      },
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'has_attachments',
      text: 'Attached',
      sort: true,
      attrs: { title: 'Attached' },
      formatter: (cell, row, rowIndex) =>
        row.has_attachments === true ? 'Yes' : 'No',
      headerStyle: {
        width: '150px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-4">
            <h6 className="mb-2">Description</h6>
            <p className="m-0">{row?.description ? row?.description : '-'}</p>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <h6 className="mb-2">Attachments</h6>
              {row.attachments.length > 0 &&
                row.attachments.map((attachment) => {
                  return (
                    <span className="d-flex align-items-center mt-2">
                      {attachment.split('/').pop().substr(37)}
                      <span
                        className="ms-3 cursor-pointer"
                        onClick={() => downloadFile(attachment)}
                      >
                        <img src={IconDownload} alt="" />
                      </span>
                    </span>
                  );
                })}
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <h6 className="mb-2">Updated Date / Time</h6>
              <p className="m-0">
                {moment(row.updated_at).format('MMMM Do YYYY HH:mm')}
              </p>
            </div>
            <div className="col-md-4">
              <h6 className="mb-2">Uploaded By </h6>
              <p className="m-0">
                {row.updated_by_name ||
                  row.updated_by_profile_settings.userName ||
                  updated_by_email_address}
              </p>
            </div>
          </div>
          <div className="button-group mt-4 mt-md-5">
            <StylishNewButton
              customButton
              className={'button--icon primary'}
              onClick={() => onUpdateSaftyForm(row)}
            >
              <SharedIcon iconName="stylus" />
            </StylishNewButton>
            <StylishNewButton
              customButton
              className={'button--icon'}
              onClick={() => onDeleteSaftyFormData(row)}
            >
              <SharedIcon iconName="delete" />
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
  };

  const onAddNewForm = () => {
    setDialogType('New');
    setShowAddEditFormDialog(true);
  };

  const onUpdateSaftyForm = (row) => {
    setUpdateSaftyFormData(row);
    setDialogType('Edit');
    setShowAddEditFormDialog(true);
  };

  const onDeleteSaftyFormData = (row) => {
    setUDeleteSaftyFormData(row);
    setShowConfirmDialog(true);
  };

  const onDeleteSaftyConfirm = () => {
    reduxDispatch(deleteSaftyAndHealthForm(deleteSaftyFormData.id));
    setUDeleteSaftyFormData(null);
    setShowConfirmDialog(false);
  };

  const downloadFile = async (file) => {
    const d = await reduxDispatch(generateSignedUrl(file.trim()));
    window.open(d, '_blank');
  };

  return (
    <>
      {featureFlags.includes('FULL_IAP_208') && (
        <div className="d-md-flex align-items-md-center mb-4">
          <StylishNewButton
            className={'button--primary'}
            onClick={() => {
              setExistingData(null);
              setDialogType('New');
              setShowAddEditFormDialog(true);
            }}
          >
            Create New Form
          </StylishNewButton>
          <StylishNewSearchBar
            className="ms-auto mt-3 mt-md-0"
            onChangeSearchTerm={onChangeSearchTerm}
          />
        </div>
      )}

      <div
        className={featureFlags.includes('FULL_IAP_208') ? 'form-block' : ''}
      >
        <h4 className="mb-4">
          Site Safety and Health Plan ICS-208-CG (rev 4/15)
        </h4>
        <div className="mb-4">
          <h4>1. Incident Name</h4>
          {reduxCurrentIncident?.name}
        </div>
        <hr className="dashed my-4" />
        <div className="mb-4">
          <h4>2. Operational Period</h4>
          {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
            <>
              <div className="row">
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="form-label d-block">From:</label>
                  {moment(
                    reduxCurrentIncident?.operational_period_start_date_time
                  ).format('MM/DD/YYYY HH:mm')}
                </div>
                <div className="col-md-6">
                  <label className="form-label d-block">To:</label>
                  {moment(
                    reduxCurrentIncident?.operational_period_start_date_time
                  )
                    .add(
                      reduxCurrentIncident?.operational_period_interval,
                      'hours'
                    )
                    .format('MM/DD/YYYY HH:mm')}
                </div>
              </div>
            </>
          )}
        </div>
        <hr className="dashed my-4" />
        <div className="mb-4">
          <h4>3. Forms</h4>
          <StylishNewButton
            type="button"
            className="button--primary mb-4"
            onClick={onAddNewForm}
            disabled={
              !reduxValidateRBACPermissionForActionResult['Write Pod Data']
            }
          >
            Add
          </StylishNewButton>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={safetyAndHealthForms}
            expandRow={expandRow}
          />
        </div>
      </div>

      {showAddEditFormDialog && (
        <IAPForm208AddEditFormDialog
          show={showAddEditFormDialog}
          onClose={() => setShowAddEditFormDialog(false)}
          dialogType={dialogType}
          existingData={updateSaftyFormData}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this form?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onDeleteSaftyConfirm()}
        />
      )}
    </>
  );
};
