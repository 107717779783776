import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import Collapse from '@mui/material/Collapse';
import {
  ListGroupDropdownItem,
  ListGroupNavItem,
} from './ListGroupNavComponents';
import { useFullIAPFlag, useFeatureFlag, useStreamlinePlatformFlag } from 'utils/featureFlags';

type IncidentDropdown = 'plan' | 'assignment';

const IncidentNavPanel: React.FC<{
  navBack: () => void;
}> = ({ navBack }) => {
  const [openDropdown, setOpenDropdown] = useState<IncidentDropdown | null>(
    null
  );

  const toggleDropdown = (dropdown: IncidentDropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdown);
    }
  };

  const streamlinePlatform = useStreamlinePlatformFlag();
  const fullIAP = useFullIAPFlag();

  return (
    <nav aria-label="secondary sidebar" className="second-panel">
      <div className="second-panel-back">
        <button
          className="button button--icon border-0 bg-bglight"
          onClick={navBack}
        >
          <i className="material-symbols-outlined">arrow_back</i>
        </button>
        <div>Incident Management</div>
      </div>
      <ListGroup>
        <div className="list-group-title">Manage Incident</div>
        <ListGroupNavItem
          to="/incident_management"
          icon="dashboard"
          label="Dashboard"
          end
        />
        <ListGroupDropdownItem
          onClick={() => toggleDropdown('plan')}
          open={openDropdown === 'plan'}
          icon="edit_square"
          label="Plan"
        />
        <Collapse in={openDropdown === 'plan'}>
          <ListGroupNavItem
            to="/incident_management/ICS_202-CG"
            label="Objectives (202)"
            secondary
          />
          <ListGroupNavItem
            to="/incident_management/ICS_206-CG"
            label="Medical (206)"
            secondary
          />
          <ListGroupNavItem
            to="/incident_management/ICS_208-CG"
            label="Safety (208)"
            secondary
          />
          {fullIAP ? (
            <>
              <ListGroupNavItem
                to="/incident_management/ICS_205-CG"
                label="Radio Communications (205)"
                secondary
              />
              <ListGroupNavItem
                to="/incident_management/ICS_205A-CG"
                label="Communications List (205a)"
                secondary
              />
            </>
          ) : null}
          <ListGroupNavItem
            to="/incident_management/IAP_COVER_SHEET"
            label="Full Incident Action Plan"
            secondary
          />
        </Collapse>
        {fullIAP ? (
          <>
            <ListGroupDropdownItem
              onClick={() => toggleDropdown('assignment')}
              open={openDropdown === 'assignment'}
              icon="calendar_month"
              label="Assignments"
            />
            <Collapse in={openDropdown === 'assignment'}>
              <ListGroupNavItem
                to="/incident_management/assignments_list"
                label="List"
                secondary
              />
              <ListGroupNavItem
                to="/incident_management/assignments_board"
                label="Board"
                secondary
              />
              <ListGroupNavItem
                to="/incident_management/assignments_calendar"
                label="Calendar"
                secondary
              />
            </Collapse>
          </>
        ) : (
          <>
            {streamlinePlatform ? null : (
              <>
                <ListGroupNavItem
                  to="/incident_management/assignments_list"
                  icon="list"
                  label="Assignments"
                />
              </>
            )}
          </>
        )}

        {streamlinePlatform ? null : (
          <ListGroupNavItem
            to="/incident_management/resource_list"
            icon="layers"
            label="Resources"
          />
        )}
        
        <ListGroupNavItem
          to="/incident_management/ICS_201-CG"
          icon="bar_chart"
          label="Analysis"
        />
      </ListGroup>
    </nav>
  );
};

export default IncidentNavPanel;
